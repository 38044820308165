var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pricing",attrs:{"id":"pricing"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"pricing-plans"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"pricing-plan"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"pricing-btn"},[_c('router-link',{staticClass:"main-btn custom-btn",attrs:{"to":"/pricing","target":"_blank"}},[_c('span',[_vm._v("Buy Now")])])],1)])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"pricing-plan two"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"pricing-btn"},[_c('router-link',{staticClass:"main-btn custom-btn",attrs:{"to":"/pricing","target":"_blank"}},[_c('span',[_vm._v("Buy Now")])])],1)])]),_c('div',{staticClass:"col-lg-4 offset-lg-0 col-md-6 offset-md-3"},[_c('div',{staticClass:"pricing-plan"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"pricing-btn"},[_c('router-link',{staticClass:"main-btn custom-btn",attrs:{"to":"/pricing","target":"_blank"}},[_c('span',[_vm._v("Buy Now")])])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title text-center"},[_c('span',{staticClass:"separator"},[_c('i',{staticClass:"flaticon-chakra"})]),_c('h2',[_vm._v("Our Pricing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-head"},[_c('div',{staticClass:"pricing-title"},[_c('h2',[_vm._v("Basic Plan")])]),_c('div',{staticClass:"pricing-price"},[_c('p',[_c('span',{staticClass:"up"},[_vm._v("$")]),_vm._v(" "),_c('span',{staticClass:"price"},[_vm._v("50")]),_c('span',{staticClass:"down"},[_vm._v("/Mo")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pricing-features list-unstyled"},[_c('li',[_vm._v("Starter Pack Included")]),_c('li',[_vm._v("Personal Trainer")]),_c('li',[_vm._v("Special Class")]),_c('li',[_vm._v("Free Tutorials")]),_c('li',[_vm._v("Group Training")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-head"},[_c('i',{staticClass:"flaticon-lotus"}),_c('div',{staticClass:"pricing-title"},[_c('h2',[_vm._v("Standard Plan")])]),_c('div',{staticClass:"pricing-price"},[_c('p',[_c('span',{staticClass:"up"},[_vm._v("$")]),_vm._v(" "),_c('span',{staticClass:"price"},[_vm._v("100")]),_c('span',{staticClass:"down"},[_vm._v("/Mo")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pricing-features list-unstyled"},[_c('li',[_vm._v("Starter Pack Included")]),_c('li',[_vm._v("Personal Trainer")]),_c('li',[_vm._v("Special Class")]),_c('li',[_vm._v("Free Tutorials")]),_c('li',[_vm._v("Group Training")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-head"},[_c('div',{staticClass:"pricing-title"},[_c('h2',[_vm._v("Premium Plan")])]),_c('div',{staticClass:"pricing-price"},[_c('p',[_c('span',{staticClass:"up"},[_vm._v("$")]),_vm._v(" "),_c('span',{staticClass:"price"},[_vm._v("170")]),_c('span',{staticClass:"down"},[_vm._v("/Mo")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"pricing-features list-unstyled"},[_c('li',[_vm._v("Starter Pack Included")]),_c('li',[_vm._v("Personal Trainer")]),_c('li',[_vm._v("Special Class")]),_c('li',[_vm._v("Free Tutorials")]),_c('li',[_vm._v("Group Training")])])
}]

export { render, staticRenderFns }